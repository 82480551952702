import React, { useState } from "react"
import { graphql } from "gatsby"
import { Modal } from "antd"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { checkShowForm, initializeHubspotForm } from "../utils/gatsby-helpers"
import Seo from "../components/seo"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import scrollTo from "gatsby-plugin-smoothscroll"
import NewLayout from "../components/new_layout"


const WhitePaperPage = ({ data, pageContext, location }) => {
    const whitePaper = data.whitePaper
    const form = whitePaper.hubspot_form

    const [isModalVisible, setIsModalVisible] = useState(checkShowForm(location))

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    initializeHubspotForm(form.portal_id, form.form_id, "#form-white-paper", form.region, { ebook_name: whitePaper.hubspot_ebook_name })

    const scrollAndShowModal = () => {
        scrollTo("#white-paper-header");
        showModal();
    }

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={pageContext.langKey}
                title={whitePaper.seo.title}
                description={whitePaper.seo.meta_description}
                translates={pageContext.translates}
                image={whitePaper.seo?.image_2?.localFile?.publicURL}
            />

            <div className="main main--ebook">
                <div className="main__section main__section--100 ebook-hero" id="white-paper-header">
                    <div className="container">
                        <div className="tag tag--highlight">eBook</div>
                        <h1 className="merriweather">{whitePaper.title}</h1>
                    </div>
                </div>
                <div className="main__section main__section--50 color-section gray">
                    <div className="container">
                        <div className="container__50">
                            <div className="container__text">
                                <ReactMarkdown
                                    children={whitePaper.description}
                                    rehypePlugins={[rehypeRaw]}
                                />
                            </div>
                            <button className="button button--xl" onClick={showModal}>{whitePaper.hero_button}</button>
                        </div>
                        <div className="container__50" data-aos="fade-left">
                            <GatsbyImage
                                alt={whitePaper.hero_image_new.alternativeText}
                                image={getImage(whitePaper.hero_image_new.localFile)}
                            />
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--100">
                    <div className="container">
                        <h2>{whitePaper.content_title}</h2>
                        <div className="container__text">
                            <ReactMarkdown children={whitePaper.content_description} rehypePlugins={[rehypeRaw]} />
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--60-40">
                    <div className="container">
                        <div className="container__60">
                            <GatsbyImage
                                alt={whitePaper.content_image_new.alternativeText}
                                image={getImage(whitePaper.content_image_new.localFile)}
                            />
                        </div>
                        <div className="container__40" data-aos="fade-left">
                            {whitePaper.content_items.map((item, index) => (
                                <div className="image-group" key={index}>
                                    <div>
                                        <GatsbyImage alt={item.icon.alternativeText} image={getImage(item.icon.localFile)} />
                                    </div>
                                    <div className="image-group__text">
                                        <h5>{item.title}</h5>
                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--100" >
                    <div className="container">
                        <div className="tile">
                            <h3>
                                <ReactMarkdown children={whitePaper.cta_title} rehypePlugins={[rehypeRaw]} />
                            </h3>
                            <button className="button" onClick={scrollAndShowModal}>{whitePaper.cta_button}<i className="icon-arrow-right"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <Modal width={"100%"} visible={isModalVisible} onCancel={handleCancel} footer={null}>
                <div id="form-white-paper" className="form-hb" />
            </Modal>

        </NewLayout>
    )
}

export default WhitePaperPage

export const whitePaperPageQuery = graphql`
    query ($id: String!, $langKey: String!) {
        whitePaper: strapiWhitePapers (locale: {eq: $langKey}, id: {eq: $id}) {
            seo {
                title
                meta_description
                image_2{
                    localFile {
                        publicURL

                    }
                }
            }
            id
            title
            description
            hero_button
            hero_image_new {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 608
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            content_title
            content_description
            content_items {
                order
                title
                description
                icon {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 80
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            content_image_new {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            cta_title
            cta_button
            hubspot_ebook_name
            hubspot_form {
                id
                region
                portal_id
                form_id
            }
        }
    }
`
